  .navbar {
    background: linear-gradient(90deg, var(--back) 0%, rgb(37, 22, 46) 100%);
    height: 60px;
    display: flex !important;
    text-align: center;
    font-size: 20px;
    align-items: center;
    width: 100%;
  }
  
  .username {
    align-items: center;
    display: flex;
  }
  
  .bare-image {
    margin-top: -17px;
  }
  
  .bare-image2 {
    height: 15px;
    cursor: pointer;
  }
  
  .navbar-logo {
    color: var(--light2) !important;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 35px;
    padding-left: 15px !important;
    text-align: center;
  }

  .nav-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    list-style: none;
    color: var(--light2);
    margin-bottom: 0px !important;
    padding-left: 55px;
    padding-right: 30px;
  }
  
  .nav-i {
    color: var(--light2);
  }

  .dropdown-text {
    margin-right: 15px;
  }
  
  .dropdown-toggle {
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .dropdown-toggle:hover {
    background-color: var(--midtone2);
    border-radius: 4px;
    transition: all 0.2s ease-out;
  }

  .nav-item {
    display: flex;
    align-items: center;
  }
  
  .nav-links {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  
  .nav-i:hover {
    background-color: var(--midtone2);
    border-radius: 4px;
    transition: all 0.2s ease-out;
    color: var(--white);
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
    height: 25px;
  }
  
  .nav-username {
    display: flex;
    font-size: 20px;
    align-items: center;
    text-align: center;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
    
    .waitlist-btn {
      margin: 15px;
    }

    .navbar-logo {
      font-size: 30px;
    }

    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 60px;
      padding-bottom: 25px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
    .nav-item {
      width: 100%;
      display: block;
    }

    .nav-menu.active {
      background: var(--back2);
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }

    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
    .dropdown-text {
      margin-right: 0;
    }

    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      padding: 1.5rem;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      background: var(--light1);
      text-decoration: none;
      color: var(--white);
      font-size: 1.5rem;
    }

    .dropdown-menu {
      display: none;
    }
    .bare-image2 {
      display: none;
    }

    button {
      display: none;
    }
  }

.nala {
    padding-top: 85px;
    padding-bottom: 75px;
}

.nala-back {
    background-color: var(--back);
}

.nala-header {
    background-color: var(--accent3);
    text-align: center;
    padding: 15px;
    font-size: 85px;
    margin: 2.5%;
    width: 95%;
    text-decoration: underline;
}

.nala-hr {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    padding-top: 15px;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.nala-content {
    background-color: var(--white);
    padding-bottom: 35px;
}

.nala-img {
    height: 400px;
    margin: 25px;
}

.nala-img1 {
    margin-left: 35%;
    box-shadow: -30px 10px 0px var(--accent3);
}

.nala-img2 {
    margin-left: 15%;
    box-shadow: 30px 10px var(--accent3);
}

.nala-img3 {
    box-shadow: 0 0 0 .75em var(--accent3);
}

.nala-text { 
    margin-right: 75px;
}

.nala-rows {
    align-items: center;
    margin: 15px;
}

.nala-img-rows {
    text-align: center;
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
    margin-bottom: 15px;
}

.nala-images {
    margin: 25px;
    background-color: var(--midtone2);
}

.nala-contact {
    margin-top: 50px;
    margin-bottom: 50px;
}

@media screen and (max-width: 991px) {
    .nala {
        align-items: center;
        text-align: center;
    }

    .nala-img {
        margin: 15px;
        height: auto;
        width: 350px;
    }
    
    .nala-img1 {
        box-shadow: 0 0 0 .75em var(--accent2);
        margin-bottom: 45px;
    }

    .nala-img2 {
        box-shadow: 0 0 0 .75em var(--accent2);
        margin-bottom: 45px;
    }
    
    .nala-img3 {
        margin-bottom: 35px;
    }

    .nala-text {
        padding: 0px;
        margin-right: 0px;
    }
    
    .nala-img-rows {
        margin-bottom: 0px;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .nala-img1 {
        margin-left: 25%;
    }
    .nala-img3 {
        height: 300px;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .nala-img3 {
        height: 350px;
    }
  }

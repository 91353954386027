.dropdown-menu {
    width: 200px;
    position: absolute;
    top: 80px;
    list-style: none;
    text-align: start;
    margin-left: -85px;
    border-style: solid;
    border-width: 2px;
    border-radius: 15px;
    border-color: #000000;
}
.dropdown-menu li {
    background: #8453b3;
    cursor: pointer;
}

.dropdown-menu li:hover {
    background: #c392e4;
}
.dropdown-menu.clicked {
    display: none;
}

.dropdown-link {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #000000;
    padding: 25px;
}

.dropdown-link:hover{
    background: var(--midtone);
    color: var(--primary);
}
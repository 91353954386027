
.greyInput {
    background-color: #979797;
    border-color: #8A6A71;
  }
  
  .footer-text {
    color: #777777;
    text-align: center;
    justify-content: center;
    padding-bottom: 15px;
  }
  
  .footer {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    text-align: center;
    position: relative;
    height: auto;
    bottom: 0;
    width: 100%;
    background-color: rgb(0, 0, 0);
  }
  
  .footer h3 {
    margin-bottom: 25px;
    margin-top: 10px;
    color: #777777;
  }
  
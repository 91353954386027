.comingSoon {
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(30deg, var(--light1) 10%, var(--accent1));
    position: relative;
    text-align: center;
    overflow: hidden;
}

.comingSoon-directory {
    padding-top: 60px;
}

.comingSoon-pre {
    padding-top: 15vh
}

.comingSoon-header {
    font-size: 50px;
    filter: drop-shadow(0 0 0.75rem var(--light3));
    padding-top: 10vh;
}

.comingSoon-text {
    padding-top: 10vh;
}
:root {
  --white: #dac9c3;
  --light1: #e7c4af;
  --light2: #c9ebe8;
  --light3: #d4c299;
  --accent1: #b87a7b;
  --accent2: #6c7264;
  --accent3: #b68f69;
  --midtone1: #7c4850;
  --midtone2: #6d7466;
  --midtone3: #96633f;
  --back: #22151f; 
  --back2: #252b1c;
  --back3: #422d20; 
  --black: #000706;
}

.cinzel {
  font-family: "Cinzel", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.bebas {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.permanent-marker {
  font-family: "Permanent Marker", cursive;
  font-weight: 400;
  font-style: normal;
}


body {
  margin: 0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-family: PTSerif;
  font-style: normal;
}
.about, .contact, .home, .recent, .projects, .art, .music, .building, .webdesign, .writing, .waitlist {
  font-size: 15px;
  color: var((--white));
}

.btn {
  background-color: var(--midtone);
  border-color: var(--midtone2);
}

.btn:hover {
  background-color: var(--midtone2);
  border-color: var(--midtone2);
}

.transbtn {
  background-color: transparent;
}

.dropdown-toggle::after {
  display: none !important;
}

.about-back {
  background-image: linear-gradient(var(--back), var(--black));
  margin: 0;
  width: 100%;
}
/* .about-content {
  margin-top: 85px;
  padding-bottom: 85px;
} */


/*  Parallax  */
/* parallax "viewport */
.parallax {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  perspective: 1px;
  background-color: var(--black);
  object-fit: cover;
}

.parallax-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

 .layer1 {
  transform: translateZ(0) scale(1);
}

/* .layer2 {
  transform: translateZ(-1px) scale(1);
  background-image: url('./Components/Images/LSideWebsite.png')
}

.layer3 {
  transform: translateZ(-2px) scale(1);
  background-image: url('./Components/Images/RSideWebsite.png');
} */

.layer-content {
  transform: translateZ(-3px) scale(1);
}

.parallax-layer.layer1 {
  width: 100%;
  height: 200vh;
  background: linear-gradient(var(--back), var--black);
}

.parallax-layer.layer2 {
  width: 100%;
}

.parallax-layer.layer3 {
  width: 100%;
}

.parallax-layer.layer-content {
  top: 80px;
  width: 100%;
  height: 50rem;
  color: var(--midtone);
  display: flex;
  justify-content: center;
}


/* linear-gradient(var(--back), black) */


@media screen and (max-width: 960px) {
  .container {
    width: 100%;
  }
  
}
